<template>
  <AdminLayout
    title="Notification Emails"
  >
    <v-list>
      <div
        v-for="(email, index) in emails"
        :key="email.id"
      >
        <v-list-item>
          <v-list-item-content>
            {{ email.address }}
          </v-list-item-content>
          <v-list-item-action>
            <v-btn icon>
              <v-icon
                color="red"
                @click="deleteEmail(email.id)"
              >
                mdi-delete
              </v-icon>
            </v-btn>
          </v-list-item-action>
        </v-list-item>
        <v-divider
          v-if="index < emails.length - 1"
        ></v-divider>
      </div>
      <v-list-item>
        <v-list-item-content>
          <v-text-field
            v-model="form.address"
            label="Email address"
          >
          </v-text-field>
        </v-list-item-content>
        <v-list-item-action>
          <v-btn icon>
            <v-icon
              color="primary"
              @click="addEmail(form).then(() => form.address = '')"
            >
              mdi-plus
            </v-icon>
          </v-btn>
        </v-list-item-action>
      </v-list-item>
    </v-list>
  </AdminLayout>
</template>

<script>
import AdminLayout from '../../layouts/AdminLayout'
import { mapActions, mapGetters } from 'vuex'

export default {
  components: {
    AdminLayout,
  },

  data: () => ({
    form: {
      address: '',
      user_id: null,
    },
  }),

  async created () {
    this.form.user_id = this.user.id
    await this.fetchEmails(this.user.id)
  },

  computed: {
    ...mapGetters('user', ['user']),
    ...mapGetters('notificationEmails', ['emails']),
  },

  methods: {
    ...mapActions('notificationEmails', ['fetchEmails', 'addEmail', 'deleteEmail']),
  },
}
</script>
